
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        

.close-menu {
  background: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 10px;
  border: 0;
  cursor: pointer;
  top: 10px;
  transition: opacity .5s ease;
  @include focusVisible($color: $white);

  &:hover {
    opacity: .8;
  }
}

.menu-wrap.open {
  right: 0;
  opacity: 1;
  transition: right .3s ease-in-out, opacity .3s ease-in-out;
  visibility: visible;
}

.menu-wrap {
  right: -100%;
  opacity: 0;
  position: fixed;
  visibility: hidden;
  top: 0;
  overflow-y: auto;
  z-index: 99999;
  background: $white;
  color: $default;
  transition: right .3s ease-in-out, opacity .3s ease-in-out, visibility .3s ease-in-out;
  padding: 55px 0 0;
  border-radius: 0 0 0 20px;
  border: 1px solid $neutral;
}

.menu-nav-list {
  @include list-reset;
}

.inner-header .nav-list-item-link.router-link-active {
  background-color: lighten($default, 25%);
}
.inner-header .menu-nav-list-item {
  @include focusVisible($color: $default);
}
.inner-header .nav-list-item-link {
  display: block;
  padding: 15px 2rem;

  &:hover {
    background-color: lighten($default, 50%);
  }
}
