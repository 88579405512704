// app base styles
.main-container {
  max-width: 1470px;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;
  @include media('<=mobile') {
    padding: 20px 15px;
  }
}
.main-wrapper {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 900px;
}

.main-panel-content {
  padding: 60px 80px;
  @include media('<=tablet') {
    padding: 25px;
  }
}
.arabic-only {
  font-family: $base-font_ar;
}
.layoutLoader-wrapper {
  background-color: #14573A!important;
}