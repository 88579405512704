
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.lang-list,
.lang-list-item {
  @include list-reset;
  outline: none;
  text-decoration: none;
  .lang-list-value {
    color:white;
  }
  &:hover,
  &:focus .lang-list-value {
    color: $secondary-elements-color;
  }
}
.lang-switcher {
  position: relative;
  text-align: center;

  .mobile-btn-close-container {
    display: none;
  }
  .lang-list {
    box-shadow: 0 4px 8px rgba(41, 56, 52, 0.09);
  }
}
.lang-switcher.open {
  .lang-list {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  .lang-button {
    &:after {
      transform: rotate(135deg);
    }
  }
}
.lang-list {
  position: absolute;
  top: 100%;
  min-width: 50px;
  width: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.5s;
}
.lang-button {
  color: white;
  font-size: 13px;
  line-height: 16px;
  padding: 5px 22px 5px 5px;
  cursor: pointer;
  background: none;
  outline: none;
  border: 0;
  transition: color 0.5s;
  position: relative;
  font-weight: 500;
  @include focusVisible();
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    width: 8px;
    height: 8px;
    border-left: 1px solid $clickable-elements;
    border-bottom: 1px solid $clickable-elements;
    transform: translateY(-50%) rotate(-45deg);
    transition: transform 0.5s;
  }
}
.lang-list-item {
  color: $headers-and-simple-text;
  font-size: 13px;
  text-align: left;
  line-height: 16px;
  padding: 5px;
  cursor: pointer;
  transition: color 0.5s;
  border-top: 1px solid $line-on-background-light;
  @include focusVisible();
  &.active .lang-list-value {
    color:white;
  }
}

@include media('<tablet') {
  .lang-switcher.open {
    .lang-wrap {
      transform: translateY(0);
      box-shadow: 0 4px 8px rgba(41, 56, 52, 0.09);
    }
    .mobile-btn {
      display: block;
    }
  }
  .lang-switcher .lang-list {
    visibility: visible;
    opacity: 1;
    position: relative;
    box-shadow: none;
  }
  .lang-wrap {
    z-index: z(base);
    @include mobile-menu-default;
  }
  .lang-switcher .mobile-btn {
    margin-left: auto;
    margin-right: 15px;
  }
  .lang-switcher {
    position: static;
    .mobile-btn-close-container {
      display: flex;

      .mobile-btn-icon-close {
        color: $clickable-elements;
      }
    }
  }
  .lang-switcher .mobile-btn .line {
    transform: rotate(-45deg) translate(-6px, 6px);
    &:last-child {
      transform: rotate(45deg) translate(0, 0);
    }
  }
  .lang-list-value {
    padding: 20px 0;
    min-width: 120px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      color: $active-elements;
    }
  }
  .lang-list-item {
    border: 0;
    padding: 0;
    cursor: default;
    display: block !important;
    text-align: center;
    &.active .lang-list-value {
      color: $clickable-elements;
    }
    &:first-child .lang-list-value {
      border-bottom: 1px solid $line-on-whiteblocks;
    }
  }
}
