
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.footer-container {
  background-color: #074D31;
  width: 100%;
  justify-content: center;
  padding: 40px 80px;
  @media screen and (max-width: 768px) {
    padding: 40px 20px;
    
  }
}

.footer-section {
  flex: 1;
}

.footer-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 24px; 
  gap: 16px; 
  margin: 0 auto;
}


.footer-top {
  display: flex;
  flex-wrap: wrap;
  padding-top: 16px; 
  padding-bottom: 40px; 
  gap: 24px; 
}

.footer-list {
  color: #ffffff; 
  display: flex;
  min-width: 180px;
  flex: 1 1 0%;
  flex-direction: column;
  row-gap: 8px;
  ul {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }
}

.footer-list-hder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
}

.footer-list-item {
  margin-bottom: 8px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  line-height: 20px;
}
.footer-list-item-scroll{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}


.footer-button {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
}
.footer-button-link{
  display: flex;
  margin-bottom: -24px;
  .open-link{
    width: 16px;
  }
  p{
  position: relative;
    top: -10px;
    right: -6px!important;
  }
}

.footer-button:hover {
  text-decoration: underline;
}


.social-icons {
  display: flex;
  gap: 8px; 
  margin-bottom: 32px;
}

.social-icon-button {
  display: flex;
  padding: 6px; 
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
}

.social-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-separator {
  width: 100%;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.2); 
}


// subfooter

/* Footer Wrapper */
.subfooter {
  width: 100%;
}

/* Footer Bar */
.subfooter-bar {
  padding: 1rem 0; /* Tailwind's py-4 */
  color: #ffffff; /* Tailwind's text-white */
  margin: 0 auto;
}

/* Footer Content */
.subfooter-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem; /* Tailwind's gap-2 */
}

@media (min-width: 768px) {
  .subfooter-content {
    flex-direction: row; /* Tailwind's md:flex-row */
    padding-top: 1rem; /* Tailwind's lg:pt-4 */
  }
}

/* Footer Text */
.subfooter-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  order: 2;
}

@media (min-width: 768px) {
  .subfooter-text {
    order: unset; /* Tailwind's md:-order-none */
    align-items: flex-start;
  }
}

.subfooter-rights {
  font-size: 12px; /* Tailwind's text-base */
  font-weight: 600; /* Tailwind's font-semibold */
  margin-bottom: 0.5rem; /* Tailwind's mb-2 */
  text-align: center;
  width: 100%;
}

@media (min-width: 768px) {
  .subfooter-rights {
    text-align: start; /* Tailwind's md:text-start */
  }
}

/* Footer Links */
.subfooter-links {
  font-weight: 400; /* Tailwind's font-normal */
  display: flex;
  gap: 1rem; /* Tailwind's gap-4 */
  justify-content: center;
  font-size: 12px;
}

@media (min-width: 768px) {
  .subfooter-links {
    justify-content: flex-start; /* Tailwind's md:justify-start */
  }
}

/* Footer Icons */
.subfooter-icons {
  display: flex;
  align-items: center;
  gap: 1rem; /* Tailwind's gap-4 */
  order: 1;
  align-self: flex-end;
}

@media (min-width: 768px) {
  .subfooter-icons {
    order: unset; /* Tailwind's md:-order-none */
    align-self: unset;
  }
}

.subfooter-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}