
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.auth-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $white;

  .auth-btn {
    border-radius: 9px;
    min-height: 41px;
    padding: 9px;
    @include focusVisible();
  }
}

.auth-main {
  flex: 1;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.06);
  background-color: $background;
  padding: 160px 15px;
  overflow: hidden;

  @include media('<=laptop') {
    padding: 120px 15px;
  }

  @include media('<tablet') {
    padding: 80px 15px;
  }

  @media screen and (max-height: 800px) {
    padding: 80px 15px;
  }

  .select-input {
    padding: 14px 11px;
    background-color: $white;
  }

  .v-select .vs__dropdown-toggle {
    min-height: 48px;
    border-radius: 6px;
    background-color: $white;
  }

  .base-button {
    padding: 14px;
    border-radius: 9px;
    border: 0;
  }

  .form-input {
    height: 48px;
    border-radius: 6px;
    background-color: $white;
  }

  .form-textarea {
    background-color: $white;
  }
}

.auth-title-wrap {
  padding: 0 10px 25px;
  text-align: center;
  border-bottom: 1px solid $line-on-background-light;
  margin-bottom: 20px;
}

.auth-message {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0;
}

.auth-title {
  @include useRtl {
    font-family: $secondary-font_ar;
  }
  @include useLtr {
    font-family: $secondary-font_en;
  }
  font-size: 42px;
  letter-spacing: .62px;
  line-height: 1.4;
  font-weight: normal;
  margin: 0 0 24px;

  &.md-title {
    font-size: 36px;
  }
}

.auth-form-wrap {
  margin-bottom: 30px;

  .custom-label {
    margin-bottom: 12px;
  }
}

.auth-container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  .base-button {
    width: 100%;
  }
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .col {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.sign-up-link {
  margin: 20px 0 5px;
  font-size: 13px;
  text-align: center;

  a {
    color: $clickable-elements;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

@include media('<=laptop') {
  .auth-message {
    font-size: 14px;
  }
  .auth-title {
    font-size: 36px;
  }
}
@include media('<=mobile') {
  .auth-btn {
    display: none;
  }
}
