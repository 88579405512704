
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: z(panel);
}
.header .logo {
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
}

.header-left{
  display: flex;
  justify-content: space-between;
}
.header-right{
  margin: 5px 27px;
}
@include media('>desktop') {
  .header {
    min-height: 104px;
  }
}
@include media('<=desktop') {
  .header {
    font-size: 11px;
    min-height: 90px;
  }
}
