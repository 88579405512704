
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.footer {
  position: fixed;
  bottom: 20px;  /* Adjust to control the distance from the bottom */
  right: 20px;    /* Adjust to control the distance from the left */
  background-color: transparent;
  z-index: 1000; /* Ensure footer stays on top */
}

.support-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: white;
  background-color: #28a745; /* Green background color */
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.support-button:hover {
  background-color: #218838; /* Darker green on hover */
  transform: translateY(-2px); /* Slight raise effect */
}

.support-button:active {
  background-color: #1e7e34; /* Even darker green when clicked */
  transform: translateY(0); /* Reset the transform effect */
}


svg {
  color: #ffffff;
  fill: #ffffff;
}