
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.home-header {
  // position: absolute;
  // left: 0;
  // top: 5.1rem;
  // width: 100%;
  // z-index: 99;
  background-color: white;
  border-bottom: 1px solid $neutral;
  box-sizing: border-box;

  .container-landing {
    padding: 0 32px;
    max-width: unset;
    width: 100%;
    margin:-1px 0;
  }
}

.home-header-wrap {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.home-header-logo {
  display: flex;
  align-items: center;
  justify-items: center;
}

.home-header-items-wrapper {
  display: flex;
  gap: 16px;
  .home-header-items {
    display: flex;
    align-items: center;

    @media (max-width: 1270px) {
      display: none;
    }
  }
}
.home-header-item {
  padding: 24px 16px;
  cursor: pointer;
  color: $default;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 6.25px;

  @media (max-width: 630px) {
    span {
      display: none;
    }
  }
}

.home-header-item:hover {
  background-color: #F3F4F6;
  position: relative;
}

.home-header-item:hover.hovered::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 1rem);
  height: 4px;
  background-color: #9DA4AE;
  z-index: 50;
  transform: translateX(0.5rem);
  transform-origin: center;
  border-radius: 0.25rem; 
}


.main-color-link.home-header-item {
  color:white;
  background-color: #1b8354;
  position: relative;
  height: 100%;
  border-radius: 0.25rem;
}

.main-color-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 1rem);
  height: 4px;
  background-color:#54C08A;
  z-index: 50;
  transform: translateX(0.5rem);
  transform-origin: center;
  border-radius: 0.25rem; 
}

.home-header-logo-label {
  font-size: rem-calc(14);
  background: $service-item-label-decor;
  color: $white;
  position: absolute;
  padding: 0 5px;
  line-height: 1.5;
  top: 0;
  left: 0;
  border-radius: 5px;
  z-index: 2;

  @include media(">=tablet") {
    left: 28px;
  }

  &:before {
    content: '';
    z-index: -1;
    position: absolute;
    left: 10px;
    top: 0;
    width: 100%;
    bottom: -1px;
    transform: skew(10deg);
    background: $service-item-label-decor;
    border-radius: 5px;
  }
}

.home-header-menu {
  display: flex;
  align-items: center;
  // margin-bottom: 25px;

  @include media(">=tablet") {
    // margin-bottom: 55px;
  }
}

.home-login-button {
  text-decoration: none;
  padding: 8px 2rem;
  cursor: pointer;
  line-height: 26px;
  display: inline-block;
  border-radius: 50rem;
  white-space: nowrap;
  color: $white;
  font-size: rem-calc(13);
  border: 2px solid $darker-clickable-elements;
  text-align: center;
  vertical-align: middle;
  transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
  @include focusVisible($color: $white);
  margin-right: 28px;
  &:last-child {
    margin-right: 0;
  }
  span {
    margin-left: 17px;
  }
  svg {
    padding: 0;
    vertical-align: middle;
    max-width: 100%;
  }
  &:hover {
    opacity: 0.8;
    background-color: $darker-clickable-elements;
  }
  @include media(">=tablet") {
    min-width: 154px;
  }
}

.home-login-menu {
  position: relative;
  // margin-right: 1rem;
  display: flex;
}

.home-header-menu-button {
  cursor: pointer;
  font-size: 20px;
  padding: 0;
  border: 0;
  line-height: 26px;
  outline: none;
  color: $default;
  display: inline-block;
  font-weight: 400;
  background-color: transparent;
  border-radius: 0.25rem;
  @include focusVisible($color: $default);
  margin-left: 28px;
  transition: color .5s ease;
  &:hover {
    color: darken($default, 50%);
  }

  @media (min-width: 1270px) {
    display: none;
  }
  
}

.home-header-logo-label-wrap {
  padding-top: 28px;
  position: relative;
}

.home-header-logo-img {
  @include focusVisible($color: $white);

  svg {
    width: 95px;
    height: 55px;

    @include media('>=tablet') {
      width: 156px;
      height: 57px;
    }

    @include media('>=laptop') {
      width: 156px;
      height: 90px;
    }
  }
}


@include media('<=tablet') {
  .home-header {
    top: 30px;
  }
  .home-header-wrap {
    min-height: 83px;
  }
  .home-login-button {
    margin-right: 16px;
  }
  .home-login-button svg {
    margin: 0;
  }
  .home-login-button span {
    display: none;
  }
  .home-header-menu-button {
    font-size: 20px;
    margin-left: 10px;
  }
  .home-header-logo {
    right: auto;
    transform: none;
    left: 0;
  }
}

.home-header .nav-list-item-link.router-link-active {
  background-color: lighten($default, 75%);
}

.home-header .nav-list-item-link {
  display: block;
  padding: 15px 2rem;
  @include focusVisible($color: $white);

  &:hover {
    background-color: lighten($default, 80%);
  }
}
