
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
@font-face {
  font-family: '29ltbukr';
  src: url('fonts/29ltbukralight.woff2') format('woff2'),
       url('fonts/29ltbukralight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '29ltbukr';
  src: url('fonts/29ltbukraregular.woff2') format('woff2'),
       url('fonts/29ltbukraregular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '29ltbukr';
  src: url('fonts/29ltbukrabold.woff2') format('woff2'),
       url('fonts/29ltbukrabold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
.landing-layout {
  font-size: $rem-base;
}
html.landing-layout body {
  // background-color: $land-footer-background;
}

.landing-layout #app {
  min-height: 100vh;
}

.web__main {
  background-color:#F9FAFB;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.main-content-inner {
  padding: 52px 0;
  flex: 1 0 auto;
}
.landing-section {
  padding: 5rem 0;
  color: $land-section-content-text;
  font-size: 18px;
  line-height: 1.5;
}

.landing-layout .landing-content {
  width: 100%;
  overflow: hidden;
  font-size: 1.125rem;
  color: $land-section-content-text;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.landing-section-title.service-item-title {
  font-weight: 700;
}

.landing-section-title {
  font-size: rem-calc(46);
  line-height: 1.2;
  font-weight: 300;
  margin: 0 0 40px;
}

.container-landing {
  max-width: 1600px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.btn-group {
  display: flex;
  justify-content: center;

  .btn {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 29px;
    text-align: center;
    text-decoration: none;
    color: $white;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
  }

  .btn--large {
    min-width: 135px;
    min-height: 37px;
    padding: 10px 20px;
    font-size: 14px;

    @include media('>tablet') {
      min-width: 218px;
      min-height: 57px;
      padding: 16px 20px;

      font-size: 20px;
    }
  }

  .btn--primary {
    background-color: $clickable-elements;

    &:hover {
      background-color: $darker-clickable-elements;
    }
  }

  .btn--border-clickable {
    border: 1px solid $clickable-elements;
    background: transparent;
    color: $white;
    text-decoration: none;

    &:hover {
      background: $clickable-elements;
    }
  }
}

@media (max-width: 1600px) {
  $rem-base: 14px;
  .container-landing {
    max-width: 1375px;
  }
  .landing-layout {
    font-size: $rem-base;
  }
}

@media (max-width: 1420px) {
  $rem-base: 12px;
  .landing-layout {
    font-size: $rem-base;
  }
}

@include media('<=laptop') {
  .landing-section {
    padding: 50px 0;
    font-size: 16px;
  }
  .landing-section-title {
    font-size: rem-calc(40);
  }
}

@include media('<=tablet') {
  .landing-section-title {
    font-size: rem-calc(35);
  }
}
