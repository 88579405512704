
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.user-menu {
  color: $headers-and-simple-text;
  font-size: 13px;
  position: relative;

  .user-menu-icon-close {
    display: none;
  }
}
.user-menu-list {
  @include list-reset;
  overflow: hidden;
  left: 0;
  right: 0;
  min-width: 160px;
  background-color: $white;
  position: absolute;
  padding-bottom: 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.5s;
  box-shadow: 0 4px 8px rgba(41, 56, 52, 0.09);
}
.user-menu-ico {
  width: 34px;
  margin-right: 14px;
}
.user-menu-label {
  display: block;
  color: white;
  border-bottom: 1px solid transparent;
  transition: border-color 0.5s;
  padding: 8px 8px 8px 0;
  max-width: 95px;
  text-decoration: none;
}
.user-menu-profile {
  font-weight: 500;
  border: 0;
  z-index: z(base);
  position: relative;
  cursor: pointer;
  background: transparent;
  padding: 0;
  text-align: left;
  margin: 0;
  outline: none;
  display: flex;
  align-items: center;
  transition: color 0.5s;
  &.user-menu-landing {
    display: none;
    font-size: 13px;
    margin-right: 25px;
    &:after {
      border-right: 1px solid $clickable-elements;
      border-left-color: transparent;
      margin-top: 0;
    }
    .user-menu-label {
      max-width: initial;
    }
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -2px;
    width: 8px;
    height: 8px;
    border-left: 1px solid $clickable-elements;
    border-bottom: 1px solid $clickable-elements;
    transform: translateY(-50%) rotate(-45deg);
    transition: transform 0.5s;
  }
  .user-menu-label {
    min-width: 60px;
    padding-right: 22px;
  }
}

.dashboard-layout {
  .user-menu-landing {
    display: flex;
  }
  .user-menu-item {
    .mq-wrapper {
      width: 100%;
    }
    .user-menu-landing {
      width: 100%;
      justify-content: center;
      &:after {
        display: none;
      }
      .user-menu-label {
        display: block;
        text-align: center;
        max-width: 100%;
        min-width: 120px;
        padding-right: 0;
      }
    }
  }
}

.user-menu-label:hover {
  color: $clickable-elements;
}
.user-menu-btn {
  border: 0;
  padding: 0;
  text-align: left;
  width: 100%;
  margin: 0 0 0 48px;
  background: none;
  cursor: pointer;
  outline: none;
  font-weight: 500;
  transition: color 0.5s;
  color: $secondary-elements-color;
  &:hover {
    color: $primary-elements-color;
  }
}
.user-menu .mobile-btn {
  display: none;
}
.user-menu-item {
  .user-menu-btn {
    margin: 0;
    padding-left: 48px;
    
    @include focusVisible(-2px)
  }
}
.user-menu.open {
  .user-menu-item {
    .user-menu-label { 
      color: $primary-elements-color;
    }
    &:last-child .user-menu-label {
      border-color: transparent;
    }
  }
  .user-menu-list {
    opacity: 1;
    pointer-events: all;
    visibility: visible;
  }
  .user-menu-label {
    border-color: $line-on-background-light;
  }
  .user-menu-profile {
    &:after {
      transform: rotate(135deg);
    }
  }
}
@include media('<tablet') {
  .user-menu-profile {
    &.user-menu-landing {
      margin-left: 0;
    }
    .user-menu-label {
      display: none;
      min-width: auto;
    }
  }
  .user-menu {
    position: static;
    min-width: 100px;
    .mobile-btn .line {
      transform: rotate(45deg) translate(6px, 6px);
    }
    .mobile-btn .line:last-child {
      transform: rotate(-45deg) translate(0px, 0);
    }
    .user-menu-btn {
      cursor: default;
      padding: 0;
    }
  }
  .user-menu-profile:after {
    right: -7px;
  }
  .user-menu-wrap {
    left: 0;
    right: 0;
    top: 0;
    padding: 20px 0;
    background-color: $white;
    position: absolute;
    z-index: z(base);
    box-shadow: 0 4px 8px rgba(41, 56, 52, 0.09);
    transform: translateY(-100%);
    transition: transform 0.5s;

    .user-menu-icon-close {
      display: block;
      margin-left: 20px;
    }
  }
  .user-menu-list {
    padding: 0;
    visibility: visible;
    pointer-events: all;
    opacity: 1;
    position: relative;
    box-shadow: none;
  }
  .user-menu .mobile-btn {
    display: block;
    margin-right: auto;
    margin-left: 15px;
  }
  .user-menu-btn {
    margin: 0;
    text-align: center;
    padding: 0;
    &:hover {
      color: $headers-and-simple-text;
    }
  }
  .user-menu-label {
    display: inline-block;
    padding: 20px 0;
    min-width: 120px;
    max-width: unset;
    cursor: pointer;
    transition: color 0.5s;
    &:hover {
      color: $clickable-elements;
    }
  }
  .user-menu.open {
    .user-menu-wrap {
      transform: translateY(0);

      .user-menu-icon-close {
        margin-left: 20px;
      }
    }
  }
}
