// library variables
@import "~@takamol/nawa-library/src/assets/scss/variables";

// app variables
$time-fast: 0.2s;
$time-medium: 0.3s;
$time-slow: 0.6s;
$time-loader: 2s;

$transition-duration: $time-medium;
$transition-property: all;
$transition-timing-function: ease;

$header-shadow: 0 2px 4px 0 rgba(#000, 0.2);

// Landing
$rem-base: 16px;
$black: #000;
$land-background: #f1f4f4;
$land-bottom-clickable-elements: #7c8889;
$land-accent-text: #add1fa;
$land-brand-lmi: #3c7fcc;
$land-green-background: #36b79f;
$land-valid-license-background: #4E9665;
$land-invalid-license-background: #9D4242;
$land-expired-license-background: #CBDBDB;
$land-section-content-text: #4C5056;
$service-item-border: #E2E2E2;
$service-item-label-decor: #3C7CCC;
$service-item-label-color: #FFCC00;
$land-footer-background: #165564;
$land-footer-top-background: #144C5A;
$land-statistic-background: #f0f5f5;
$home-services-nav-tab-bg: #20687A;
$home-main-banner-bg: #232323;
$service-grey-color: #96B5B6;
$description-grey-text: #8B9A9F;
$clickable-elements-hover: #079278;
$warning-notification-background: #c27171;
$list-item-hover: #E6ECEC;
$secondary-elements-color: #03D1AB;
$primary-elements-color:#005364;
$warning-elements-color:#C02534;

$default: #161616;
$neutral: #D2D6DB;



