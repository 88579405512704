
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.about-layout {
  min-height: 100vh;
  overflow: hidden;
  .about-main {
    height: 100vh;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .about-inner {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .loader-wrapper {
    height: 100vh;
    padding-top: 100px;
  }
  .logo-img .cls-1 {
    fill: $white;
  }
}

@include media('<tablet') {
  .about-layout {
    .about-inner {
      flex-direction: column;
    }
    .about-main {
      height: auto;
    }
    .sidebar-wrap {
      min-height: auto;
    }
  }
}
