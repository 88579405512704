
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.pending-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: z(panel);
  padding: 29px 65px;
  background-color: $white;
}
@include media('>desktop') {
  .pending-header {
    min-height: 104px;
  }
}
@include media('<=desktop') {
  .pending-header {
    font-size: 11px;
    padding: 22px 15px;
    min-height: 90px;
  }
}
