
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.pending-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pending-content {
  width: 100%;
  display: flex;
  flex: 1;
  padding-top: 100px;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.06);
  background-color: $background;
}
