
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        

.toaster {
  @include list-reset;
  max-width: 350px;
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: z(modal) + 1;
}
