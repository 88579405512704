
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/helpers.scss";
        
.dashboard-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $primary-elements-color;
}

.main-wrapper {
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;
}

.dashboard-main {
  flex: 1;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.06);
  background-color: $background;
  padding: 40px 0;
  overflow: hidden;

  .breadcrumbs,
  .space-breadcrumbs {
    margin-top: -40px;
  }
  .wizard-form {
    .footer-btn {
      background-color:$secondary-elements-color!important;
      border-color: $primary-elements-color!important;
    }
    .footer-btn:hover {
      background-color: $secondary-elements-color!important;
      border-color: $secondary-elements-color!important;
    }
    .form-control:not(.form-error) .form-input:hover {
      border-color:$secondary-elements-color!important;
    }
    .v-select:not(.vs--disabled) .vs__dropdown-toggle:hover {
      border-color: $secondary-elements-color!important;
    }
  }
}

.dashboard-page {
  width: 100%;
}

.terms-info {
  white-space: pre-wrap;
  color: $clickable-elements;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.user-entity-step.goals {
  flex-wrap: wrap;
  grid-gap: 30px;
}
.user-entity-step .v-select {
  .vs__dropdown-menu {
    max-height: 140px;
  }
}

.entity-loader {
  min-height: 200px;
}

.detailed-classification {
  margin-bottom: 15px;
}

.detailed-classification.disabled {
  opacity: 0.5;
}

.conditions-bullet-list {
  padding: 20px 30px;

  @include media('>=tablet') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 80px;
    margin-bottom: 40px;
  }

  .bullet-item {
    padding: 10px 0 10px 20px;

    &:before {
      top: 13px;
    }
  }
}
.create-entity {
  .steps-button:not(.disabled):not(.active) {
    .steps-number {
      &:hover {
        background-color: $clickable-elements-hover;
      }
    }
  }
}
@include media('>tablet') {
  .create-entity {
    .steps-button {
      margin: 0 12px;

      &::before {
        width: 24px;
      }

      &.disabled:before {
        width: 24px;
      }
    }
  }
}

@include media('<=mobile') {
  .create-entity .steps-switcher-wrapper {
    text-align: center;
    overflow-x: auto;
    position: relative;
    @include customScroll(transparent);
  }
  .create-entity .main-panel-top {
    padding: 20px 20px 10px;
  }
  .create-entity .main-panel-top .steps-switcher {
    margin: 15px 0 0 0;
  }
  .create-entity .steps-switcher {
    width: auto;
    height: 35px;
    display: inline-flex;
    justify-content: unset;
    position: relative;
  }
}

.user-entity-step-requirements-list {
  counter-reset: my-counter;
  font-size: 14px;
  line-height: 1.28;

  li {
    counter-increment: my-counter;
    position: relative;
    padding-left: 27px;
    margin-bottom: 22px;

    &:before {
      content: counter(my-counter);
      font-weight: 700;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.entity-bank-step {
  &.user-entity-step {
    padding-top: 40px;
  }
}

.entity-bank-label {
  &.add-indent {
    .custom-label-name {
      margin-top: 12px;
    }
  }

  .custom-label-name {
    margin: 2px 0;
  }
}

.footer-btn {
  @include focusVisible(-2px);
}

@include media('>tablet') {
  .create-wrapper {
    .steps-button {
      margin: 0 12px;

      &::before {
        width: 24px;
      }

      &.disabled:before {
        width: 24px;
      }
    }
  }
}

@include media('<=mobile') {
  .create-wrapper .steps-switcher-wrapper {
    text-align: center;
    overflow-x: auto;
    position: relative;
    @include customScroll(transparent);
  }
  .create-wrapper .main-panel-top .steps-switcher {
    margin: 15px 0 0 0;
  }
  .create-wrapper .steps-switcher {
    width: auto;
    height: 35px;
    display: inline-flex;
    justify-content: unset;
    position: relative;
  }
}
